import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { shape } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { GroupAnimation, GroupedTextAnimation } from '../components/animations/group-animation';
import { Break } from '../components/content/break/break';
import { Related } from '../components/content/related';
import { Metadata } from '../components/core/metadata';
import { create } from '../helpers/bem';
import { resolve } from '../helpers/urls';
import { mdxType } from '../types/mdx';

import './article-template.scss';

const bem = create('articles');

const ArticleTemplate = ({ data }) => {
  const {
    mdx: {
      frontmatter: {
        title,
        intro,
        embeddedImagesLocal,
      },
      body,
    },
  } = data;
  const { t } = useTranslation();

  return (
    <Metadata
      title={title}
      description={intro}
    >
      <div className={bem()}>
        <GroupAnimation as="article" className={bem('article')}>
          <header className={bem('header')}>
            <GroupedTextAnimation as="h2" className={bem('title')}>
              {title}
            </GroupedTextAnimation>
            <p className={bem('intro')}>{intro}</p>
          </header>
          <MDXProvider>
            <MDXRenderer localImages={embeddedImagesLocal}>{body}</MDXRenderer>
          </MDXProvider>
        </GroupAnimation>
        <Break
          className={bem('break')}
          title={t('Back to overview')}
          to={resolve('articles:list')}
        />
        <Related className={bem('related')} title={t('More articles')} />
      </div>
    </Metadata>
  );
};

export default ArticleTemplate;

ArticleTemplate.propTypes = {
  data: shape({ mdx: mdxType }).isRequired,
};

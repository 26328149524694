import { number, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { create } from '../../../helpers/bem';

import './heading.scss';

const bem = create('content-heading');

export const Heading = ({
  year, company, category, className,
}) => {
  const { t } = useTranslation();

  return (
    <aside role="note" className={bem(null, null, className)}>
      <dl className={bem('list')}>
        <dt className={bem('term')}>{t('Year')}</dt>
        <dd className={bem('description')}>
          <time dateTime="2021">{year}</time>
        </dd>
        <dt className={bem('term')}>{t('Company')}</dt>
        <dd className={bem('description')}>{company}</dd>
        <dt className={bem('term')}>{t('Category')}</dt>
        <dd className={bem('description')}>{category}</dd>
      </dl>
    </aside>
  );
};

Heading.propTypes = {
  year: number.isRequired,
  company: string.isRequired,
  category: string.isRequired,
  className: string,
};

Heading.defaultProps = {
  className: null,
};

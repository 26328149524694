import { graphql } from 'gatsby';
import {
  shape,
} from 'prop-types';
import React from 'react';

import ArticleTemplate from '../../templates/article-template';
import WorkTemplate from '../../templates/work-template';
import { mdxType } from '../../types/mdx';

const MdxPage = ({ data }) => {
  const {
    mdx: {
      frontmatter: { variant },
    },
  } = data;
  const templates = {
    work: <WorkTemplate data={data} />,
    articles: <ArticleTemplate data={data} />,
  };

  return (
    <>
      {templates[variant] ? templates[variant] : null}
    </>
  );
};

export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        variant
        title
        intro
        date
        company
        category
        link
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

MdxPage.propTypes = {
  data: shape({ mdx: mdxType }).isRequired,
};

export default MdxPage;

import { string } from 'prop-types';
import React from 'react';

import { create } from '../../../helpers/bem';
import { Link } from '../../common/link';

import './break.scss';

const bem = create('content-break');

export const Break = ({ to, title, className }) => (
  <div className={bem(null, null, className)}>
    <Link to={to} className={bem('link')}>
      {title}
    </Link>
  </div>
);

Break.propTypes = {
  to: string.isRequired,
  title: string.isRequired,
  className: string,
};

Break.defaultProps = {
  className: null,
};

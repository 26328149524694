import { graphql, useStaticQuery } from 'gatsby';
import { string } from 'prop-types';
import React from 'react';

import { create } from '../../../helpers/bem';
import { Carousel } from '../carousel';

import './related.scss';

const bem = create('content-related');

export const Related = ({ title, className }) => {
  const {
    allMdx: { edges },
  } = useStaticQuery(graphql`
    query {
      allMdx(
        sort: {fields: frontmatter___date, order: DESC}
        filter: {fileAbsolutePath: {regex: "/(work)/.*\\.mdx$/"}}
      ) {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              intro
              date
              color
              frameImage {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <aside className={bem(null, null, className)}>
      <h2 className={bem('title')}>{title}</h2>
      <Carousel className={bem('carousel')} items={edges} />
    </aside>
  );
};

Related.propTypes = {
  title: string.isRequired,
  className: string,
};

Related.defaultProps = {
  className: null,
};
